import { mapGet } from "resources/utils";
export function getPageCode(
  from: string,
): (typeof CODE_PAGE)[keyof typeof CODE_PAGE] | "all" {
  const [page] = from
    .split("/")
    .filter(splitUrl => splitUrl !== "" && splitUrl !== "edit")
    .reverse();
  return mapGet(CODE_PAGE, page as keyof typeof CODE_PAGE) || "all";
}

export const CODE_PAGE = {
  login: "expert-login",
  "forgotten-password": "expert-reset-password",
  resetPassword: "expert-reset-password",
  otp: "expert-otp",
  profile: "expert-profile",
  requests: "expert-requests-list",
  request: "expert-request",
  documents: "expert-request-document",
  chat: "expert-request-message",
} as const;
